import React from 'react'
import { Route, Routes } from 'react-router'
import Login from '../auth/pages/Login'
import Logout from '../auth/pages/Logout'
//import Register from '../auth/pages/Register'
import Profile from '../auth/pages/Profile'
import Dashboard from '../dashboard/pages/Dashboard'
import DashboardLinks from '../dashboard/pages/DashboardLinks'
import UserListPage from '../users/pages/UserListPage'
import UserRolePage from '../users/pages/UserRolePage'

import SystemMasterListPage from '../master/pages/SystemMasterListPage'
import SchoolSettingsPage from '../app/settings/pages/SchoolSettingsPage'
import FeesSettingPage from '../app/settings/pages/FeesSettingPage'
import StudentListPage from '../app/student/pages/StudentListPage'
import StudentDetailsPage from '../app/student/pages/StudentDetailsPage'
import StudentAdmissionPage from '../app/student/pages/StudentAdmissionPage'
import StudentFeesStructurePage from '../app/student/pages/StudentFeesStructurePage'
import GenerateInvoice from '../app/invoice/pages/GenerateInvoice'
import ReceiptPage from '../app/invoice/pages/ReceiptPage'
import TeacherFormPage from '../app/teacher/pages/TeacherFormPage'
import TeacherListPage from '../app/teacher/pages/TeacherListPage'
import TeacherDetailsPage from '../app/teacher/pages/TeacherDetailsPage'
import StudentAttendance from '../app/Attendance/pages/StudentAttendance'
import EditStudentAttendancePage from '../app/Attendance/pages/EditStudentAttendancePage'
import FeeStructurePage from '../app/FeeStructure/pages/FeeStructurePage'
import StudentIdCard from '../app/IDCard/pages/StudentIdCard'
import BatchIdCard from '../app/IDCard/pages/BatchIdCard'
import StudentReceiptList from '../app/invoice/pages/StudentReceiptList'
import AttendanceReportClassWise from '../app/Attendance/pages/AttendanceReportClassWise'
import StudentAttendanceReportPage from '../app/student/pages/StudentAttendanceReportPage'
import HomeWorkListPage from '../app/Communication/pages/HomeWorkListPage'
import ActivityListPage from '../app/Communication/pages/ActivityListPage'
import EventListPage from '../app/Communication/pages/EventListPage'
import AnnouncementListPage from '../app/Communication/pages/AnnouncementListPage'
import NewsListPage from '../app/Communication/pages/NewsListPage'
import MessageListPage from '../app/Communication/pages/MessageListPage'
import StudentAssignFeesStructurePage from '../app/student/pages/StudentAssignFeesStructurePage'
import TransportListPage from '../app/Transport/pages/TransportListPage'
import TransportDetailsPage from '../app/Transport/pages/TransportDetailsPage'
import StudentAssignTransportPage from '../app/student/pages/StudentAssignTransportPage'
import FeesConcessionPage from '../app/FeeStructure/pages/FeesConcessionPage'
import HolidayCalendarPage from '../app/settings/pages/HolidayCalendarPage'
import FeeStructureListPage from '../app/FeeStructure/pages/FeeStructureListPage'
import FeesCollectionReportPage from '../app/invoice/pages/FeesCollectionReportPage'
import FeesCollectionListPage from '../app/invoice/pages/FeesCollectionListPage'
import ChangePassword from '../auth/pages/ChangePassword'
import StudentRemoveFeeStructurePage from '../app/student/pages/StudentRemoveFeeStructurePage'
import TermWiseFeeCollectionList from '../app/reports/pages/TermWiseFeeCollectionList'
import ClassTeacherReport from '../app/reports/pages/ClassTeacherReport'
import SyllabusPage from '../app/settings/pages/SyllabusPage'
import ClassTimetablePage from '../app/settings/pages/ClassTimetablePage'



export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={ <Dashboard/> } />
      <Route path="/dashboard" element={ <Dashboard /> } />
      <Route path="/auth/login" element={ <Login/> } />
      {/*<Route path="/auth/register" element={ <Register/> } /> */ }
      
      <Route path="/auth/change-password" element={ <ChangePassword /> } />
      <Route path="/auth/profile" element={ <Profile/> } />
      <Route path="/auth/logout" element={ <Logout/> } />


      <Route path="/system-master/list" element={ <SystemMasterListPage /> } />
      <Route path="/settings/school" element={ <SchoolSettingsPage /> } />
      <Route path="/settings/fees" element={ <FeesSettingPage/> } />
      <Route path="/settings/holiday" element={ <HolidayCalendarPage /> } />
      <Route path="/user/list" element={ <UserListPage/> } />
      <Route path="/user/role" element={ <UserRolePage/> } />


      <Route path="/student/list" element={ <StudentListPage /> } />
      <Route path="/student/details/:id" element={ <StudentDetailsPage /> } />
      <Route path="/student/admission" element={ <StudentAdmissionPage /> } />
      <Route path="/student/assign/fees-structure/:id" element={ <StudentAssignFeesStructurePage/> } />
      <Route path="/student/remove/fees-structure/:id" element={ <StudentRemoveFeeStructurePage/> } />
      <Route path="/student/assign/transport/:id" element={ <StudentAssignTransportPage /> } />
      <Route path="/student/fees-structure/:id" element={ <StudentFeesStructurePage /> } />

      

      <Route path="/student/attendance-report/:studentId" element={ <StudentAttendanceReportPage /> } />


      <Route path="/invoice/generate" element={ <GenerateInvoice /> } />
      <Route path="/invoice/collection/list" element={ <FeesCollectionListPage  /> } />
      <Route path="/invoice/collection/report" element={ <FeesCollectionReportPage /> } />
      <Route path="/invoice/receipt/:id" element={ <ReceiptPage /> } />
      <Route path="/invoice/receipt/student/:studentId" element={ <StudentReceiptList/> } />


      <Route path="/teacher/list" element={ <TeacherListPage /> } />
      <Route path="/teacher/details/:id" element={ <TeacherDetailsPage /> } />
      <Route path="/teacher/form" element={ <TeacherFormPage /> } />


      <Route path="/attendance/student" element={ <StudentAttendance /> } />
      <Route path="/attendance/batch/:batchId" element={ <EditStudentAttendancePage /> } />

      <Route path="/attendance/report/class-wise" element={ <AttendanceReportClassWise /> } />


      <Route path="/fee-structure" element={ <FeeStructurePage /> } />
      <Route path="/fee-concession" element={ <FeesConcessionPage /> } />
      <Route path="/fee-structure/list" element={  <FeeStructureListPage /> } />


      <Route path="/id-card/student/:studentId" element={ <StudentIdCard/> } />
      <Route path="/id-card/batch" element={ <BatchIdCard/> } />


      <Route path="/home-work/list" element={ <HomeWorkListPage /> } />
      <Route path="/activity/list" element={ <ActivityListPage /> } />
      <Route path="/event/list" element={ <EventListPage /> } />
      <Route path="/announcement/list" element={ <AnnouncementListPage /> } />
      <Route path="/news/list" element={ <NewsListPage /> } />
      <Route path="/Message/list" element={ <MessageListPage/> } />



      <Route path="/transport/list" element={ <TransportListPage /> } />
      <Route path="/transport/details/:id" element={ <TransportDetailsPage /> } />



      <Route path="/report/term-wise-collection-report" element={ <TermWiseFeeCollectionList /> } />

      <Route path="/report/class-teacher-report" element={ <ClassTeacherReport /> } />



      <Route path="/settings/syllabus" element={ <SyllabusPage /> } />
      <Route path="/settings/class-timetable" element={ <ClassTimetablePage /> } />


    </Routes>
  )
}