import React from 'react'
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout';
import ComposeMessage from '../components/ComposeMessage';

function MessageListPage({AppAlert}) {
    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row pt-4">
                    <div className="col-md-12">
                        
                        <span className="float-start"><h4>Message</h4></span>
                        <span className="float-end"><ComposeMessage AppAlert={AppAlert} /></span>
                    </div>
                </div>
            </div>
        </AppLayout>
        
    )
}

export default withAlert(MessageListPage);