import React, { useEffect, useState } from 'react'

import AppLayout from '../../../layout/components/AppLayout'
import useMasterList from '../../../hooks/useMasterList'
import BranchClassFilter from '../components/BranchClassFilter';
import reportServices from '../reportServices';
import { ScreenSearchDesktop } from '@mui/icons-material';
import { Link } from 'react-router-dom';

function TermWiseFeeCollectionList() {


    const [masters] = useMasterList(['branch','class']);
    const [searchingInProgress, setSearchingStatus] = useState(false);

    const [studentReport,setStudentReport] = useState(null);
    const [paymentMonths,setPaymentMonths] = useState(null);
    const getList = (values) =>{
        console.log("values",values);
        setSearchingStatus(true);
        reportServices.termWiseCollectionReport(values).then(res=>{
            setStudentReport(res.report);
            setPaymentMonths(res.paymentMonths);
        }).finally(()=>
            setSearchingStatus(false)
        )
    }


    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <BranchClassFilter masters={masters} searchingInProgress={searchingInProgress} filterData={getList} />
                    </div>
                </div>
                {paymentMonths && studentReport &&
                    <div className="row">
                        <div className="col-md-12 table-responsive">
                            <table className="table table-striped table-sm table-bordered border-dark" style={{fontSize:10}}>
                                <thead>
                                    
                                    <tr>
                                        <th rowSpan="2" className="border-dark" >#</th>
                                        <th rowSpan="2" className="border-dark table-info" >Student Name</th>
                                        <th rowSpan="2" className="border-dark table-info">Admission No</th>
                                        <th rowSpan="2" className="border-dark table-info">Class</th>
                                        
                                        {paymentMonths.map(month=>
                                            <>
                                                <th colSpan="5" className={month.month % 2 == 1 ? "table-warning text-center border-dark":"table-primary text-center border-dark" }>{month.monthTitle}</th>
                                            </>
                                        )}
                                        <th colSpan="5" className="text-center bg-dark text-white">Total</th>
                                    </tr>
                                    <tr>
                                        {paymentMonths.map(month=>
                                            <>
                                                <th className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }>{"Actual Amount"}</th>
                                                <th className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }>{"Concession"}</th>
                                                <th className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }>{"Net Receivable"}</th>
                                                <th className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }>{"Collected"}</th>
                                                <th className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }>{"Dues"}</th>
                                            </>
                                        )}
                                        <th className="table-success border-dark">{"Total Actual Amount"}</th>
                                        <th className="table-success border-dark">{"Total Concession"}</th>
                                        <th className="table-success border-dark">{"Total Net Receivable"}</th>
                                        <th className="table-success border-dark">{"Total Collected"}</th>
                                        <th className="table-success border-dark">{"Total Dues"}</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {studentReport.map((student,i)=>
                                        <tr key={student.id}>
                                            <td>{i+1}</td>
                                            <td className="table-info border-dark"
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    maxWidth: "80px",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                {student.studentName}
                                            </td>
                                            <td className="table-info border-dark">
                                                <Link className="text-dark border-dark" target="_blank" to={"/student/details/"+student.id}>{student.admissionNo}</Link>
                                            </td>
                                            <td className="table-info border-dark">
                                                {student.classTitle}-{student.sectionTitle}
                                            </td>
                                            {paymentMonths.map(month=>
                                            <>
                                                <td className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }>{student[month.monthTitle+"_Orignal"]}</td>
                                                <td className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }>{student[month.monthTitle+"_Concession"]}</td>
                                                <td className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }>{student[month.monthTitle+"_Payable"]}</td>
                                                <td className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }>{student[month.monthTitle+"_PaidAmount"]}</td>
                                                <td className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }>{student[month.monthTitle+"_Dues"]}</td>
                                            </>
                                            )}
                                            <td className="table-success border-dark">{student["Total"+"_Orignal"]}</td>
                                            <td className="table-success border-dark">{student["Total"+"_Concession"]}</td>
                                            <td className="table-success border-dark"><Link className="text-dark" target="_blank" to={"/student/fees-structure/"+student.id}>{student["Total"+"_Payable"]}</Link></td>
                                            <td className="table-success border-dark"><Link className="text-dark" target="_blank" to={"/invoice/receipt/student/"+student.id}>{student["Total"+"_PaidAmount"]}</Link></td>
                                            <td className="table-success border-dark">{student["Total"+"_Dues"]}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </div>
        </AppLayout>
    )
}

export default TermWiseFeeCollectionList