import React, { useCallback, useEffect, useRef, useState } from 'react'
import AppLayout from '../../../layout/components/AppLayout'
import systemMasterServices from '../../../master/systemMasterServices';
import studentServices from '../studentServices';

import { LinearProgress } from '@mui/material';


import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import moment from 'moment';
import { FileDownload } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import useMasterList from '../../../hooks/useMasterList';



const CellLink = (props) =>{
    return (
        <>
            {props && <Link to={`/student/details/${props.data.id}`}>{props.data.admissionNo }/{ moment(props.data.admissionYear).format("YY")}</Link> }
        </>
    )
}


function StudentListPage() {

    const [studentList,setStudentList] = useState([]);
    const gridRef = useRef();

    const [defaultColDef] = useState({
        floatingFilter: true,
        filterParams: {
          buttons: ['apply'],
        },
        resizable: true,
        width:120,
        filter:true,
        sortable: true,
        cellStyle: { 'textTransform': 'uppercase' },
    })

    

    const [columnDefs,setColumnDefs] = useState([]);


    const setupColDef = () =>{
        setColumnDefs([
            {field:"firstName"},
            {field:"lastName"},
            {field:"Gender", valueGetter: params => getMasterLabel("gender",params.data.gender)},
            {field:"admissionNo", cellRenderer: CellLink},
            {field:"uid"},
            {field:"familyId"},
            {field:"regNo"},
            {field:"branch", valueGetter: params => getMasterLabel("branch",params.data.branch)},
            {field:"medium",valueGetter: params => getMasterLabel("medium",params.data.medium)},
            {field:"class",valueGetter: params => getMasterLabel("class",params.data.class)},
            {field:"section",valueGetter: params => getMasterLabel("section",params.data.section)},
            {field:"house",valueGetter: params => getMasterLabel("house",params.data.house)},
            {field:"rollNo"},
            {field:"fatherName"},
            {field:"fatherPhone"},
            {field:"fatherOccupation", valueGetter: params => getMasterLabel("occupation",params.data.fatherOccupation)},
            {field:"motherName"},
            {field:"motherPhone"},
            {field:"motherOccupation", valueGetter: params => getMasterLabel("occupation",params.data.motherOccupation)},
            {field:"totalSiblings"},
            {field:"Address", valueGetter: params => params.data.addressLine1+","+params.data.addressLine2},
            {field:"city", valueGetter: params => getMasterLabel("city",params.data.city)},
            {field:"state",valueGetter: params => getMasterLabel("state",params.data.state)},
            {field:"pincode"},
            {field:"area",valueGetter: params => getMasterLabel("area",params.data.area)},
            {field:"dob", valueGetter: params => moment(params.data.dob).format("DD-MMM-YYYY")},
            {field:"admissionDate", valueGetter: params => moment(params.data.admissionDate).format("DD-MMM-YYYY")},
            {field:"bloodGroup",valueGetter: params => getMasterLabel("blood-group",params.data.bloodGroup)},
            {field:"caste",valueGetter: params => getMasterLabel("caste",params.data.caste)},
            {field:"religion",valueGetter: params => getMasterLabel("religion",params.data.religion)},
            {field:"category",valueGetter: params => getMasterLabel("category",params.data.category)},
            {field:"specialCategory",valueGetter: params => getMasterLabel("special-category",params.data.specialCategory)},
            {field:"nationality",valueGetter: params => getMasterLabel("nationality",params.data.nationality)},
            {field:"height"},
            {field:"weight"},

        ]);
    }

    const onGridReady =(params)=>{
        getStudentList();
    }



    const [loading,setLoadingStatus] = useState(true);

    const getStudentList = () =>{
        setLoadingStatus(true);
        studentServices.get().then(res=>{
            setStudentList(res.data);
            setLoadingStatus(false);
            setupColDef()
        }).catch(error=>{
            setStudentList([]);
        })
    }

    const [masters,getMasterLabel] = useMasterList(["area", "category", "special-category", "medium","branch","gender","class","section", "house","city", "state","blood-group","religion","caste","nationality","occupation","qualification"]);

    

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    /* Get Master Details Start */

    return (
        <AppLayout>
            <div className="container-fluid mt-3">
                {masters && 
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <button type="button" onClick={onBtnExport} className="btn btn-primary float-end"> <FileDownload/> Export</button>
                        </div>
                        <div className="col-md-12">
                        {loading && <LinearProgress  /> }
                        </div>
                        <div className="col-md-12">
                        <div className="ag-theme-alpine" style={{height: 550, width:"auto"}}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={studentList}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    animateRows={true}
                                    onGridReady={onGridReady}
                                    >
                                </AgGridReact>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </AppLayout>
    )
}

export default StudentListPage