import React, { useCallback, useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@mui/material";
import withAlert from '../../hoc/withAlert'

import api from "../../api/api";





const ImageInlineAws = ({ label, name, value, editId, updateInline, AppAlert,module}) => {

  
  const [dialogStatus, setDialogStatus] = useState(false);
  const [uploadInProgress, setUploadProgress] = useState(null);
  const [inputValue,setInputValue] = useState(value);
  const [imagePath,setImagePath] = useState(null);
  const [uploaded ,setUploadStatus] =  useState(false);


  console.log("Label ",label)


  
  const isImage = (file) =>{
    if(file.type.match('image.*')){
        return true;
    }
    return false;
  }
  

  const startUpload = (e) =>{

    if(!isImage(e.target.files[0])){
        AppAlert("Invalid File Type","error");
        return false;
    }
    
    //console.log("I am ahere");
    let data = new FormData();
    data.append('tmpFile', e.target.files[0]);
    data.append('module',module);
    data.append('editId',editId);

    setUploadStatus(false);
    api.post("upload/image",data,{
        onUploadProgress: (progressEvent) => { 
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            //console.log(percent);
            setUploadProgress(percent)
        }
    }).then( (res)=>{
        

          setUploadProgress(null)
          setUploadStatus(true);
          setInputValue(res.data.filePath);
          setTimeout(()=>{
            setImagePath(res.data.filePath);  
          },100)
          


        console.log("here in upload aws success",res);
    }).catch( (error) => {
        setUploadProgress(null)
        console.log(error);
        
    })

  }

  const addNew = ()=>{
      setDialogStatus(true)
  }

  const handleClose  = () =>{
      setDialogStatus(false);
  }

  const prepairImagePath = useCallback(() =>{
    if(inputValue){
        console.log("inputValue is",inputValue)
      setImagePath(inputValue);
    } else {
      setImagePath("/images/blank-profile.png")
    }
  },[inputValue])



  const  updateImage = () =>{
    let data = {};
    data[name] = inputValue;
    data['id'] = editId;
    let payload = {
            data:data,
            type:"image",
        }
    
    updateInline(payload).then(res=>{
        AppAlert("Updated Successfully")
    }).catch(error => {
        AppAlert(error.msg);
    })
  }

  useEffect(()=>{
    prepairImagePath();
  },[prepairImagePath])


  return (
    <>
          <div className="v2-img-container">
              <img className="v2-image" src={imagePath} alt={label} />
              <div className="v2-middle">
                <button type="button" className="btn btn-danger btn-sm" onClick={addNew}>Change</button>
            </div>
          </div>
      

          <Dialog
              open={dialogStatus}
              keepMounted
              onClose={handleClose}
              fullWidth={true}
              maxWidth="md"
              aria-describedby="alert-dialog-slide-description"
          >

          <DialogTitle>{"Update "+label}</DialogTitle>
              <DialogContent>

                <div className="row">
                

                { uploadInProgress !== null && uploadInProgress < 100 && <LinearProgress variant="determinate" value={uploadInProgress} /> }
                { uploadInProgress === null && <img className="img-thumbnail v2-thumb" src={imagePath} alt={label} />}
                  
                </div>
                <div className="row">
                  <div className="col-md-12 mt-2">
                    <input type="file" onChange={ (e)=>{ startUpload(e) } } accept="image/png, image/gif, image/jpeg" />
                    { /*<label htmlFor="upload" className="btn btn-primary  float-end">Choose File</label> */ }
                  </div>
                  
                </div>

                
                
              </DialogContent>
              <DialogActions>
                {uploaded  && <button type="button" className="btn btn-success" onClick={updateImage} >Update</button> }
                <button type="button" className="btn btn-danger" onClick={handleClose} >Close</button>
              </DialogActions>
          </Dialog>
    </>
  )
}


export default withAlert(ImageInlineAws);