import api from "../../api/api";


const get = (studentId) =>{
    return new Promise((resolve, reject)=>{
        api.get("student/fees-structure/"+studentId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const save = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("student/fees-structure",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const assignFeesStructure = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("student/fees-structure/assign",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const updateInline = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.put("student/fees-structure",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}



const removeByStudentId = (studentId) =>{
    return new Promise((resolve, reject)=>{
        api.delete("/student/fees-structure/student/"+studentId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const studentFeesStructureService = {
    get,
    updateInline,
    save,
    assignFeesStructure,
    removeByStudentId,
}
export default studentFeesStructureService;