import { Mail, PhoneAndroid } from '@mui/icons-material'
import React from 'react'
import TeacherEditFormComponent from './TeacherEditFormComponent'
import TeacherAssignedClassList from './TeacherAssignedClassList'
import AwsImageInline from '../../../components/InlineEdit/AwsImageInline'
import teacherServices from '../teacherServices'



const LabelValue = ({label,value}) =>{
    return (
        <div className="row">
            <div className="col-md-5">
                <strong>{label}</strong>
            </div>
            <div className="col-md-7">
                {value}
            </div>
        </div>
    )
}

const TeacherInformation = ({teacherDetails,getMasterLabel,masters,AppAlert, refreshData}) =>{
    return (
        <>
            <div className="row">
                <div className="col-md-9">
                    <h4>{getMasterLabel("salutation",teacherDetails.salutation)} {teacherDetails.firstName} {teacherDetails.lastName}</h4>
                    <h5><PhoneAndroid/> {teacherDetails.phone} <Mail/> {teacherDetails.email}</h5>
                </div>
                <div className="col-md-3">
                    <TeacherEditFormComponent teacherDetails={teacherDetails} masters={masters} AppAlert={AppAlert} refreshData={refreshData} />
                </div>
                <hr/>
            </div>
            <LabelValue label="TeacherId" value={teacherDetails.teacherUID} />
            <LabelValue label="Gender" value={getMasterLabel("gender",teacherDetails.gender)} />
            <LabelValue label="Branch" value={getMasterLabel("branch",teacherDetails.branch)} />
            <LabelValue label="Department" value={getMasterLabel("department",teacherDetails.department)} />
            <LabelValue label="Designation" value={getMasterLabel("designation",teacherDetails.designation)} />
            <LabelValue label="Contract Type" value={getMasterLabel("job-contract-type",teacherDetails.jobContractType)} />
            <LabelValue label="Qualification" value={getMasterLabel("qualification",teacherDetails.qualification)} />
            <LabelValue label="Teacher Group" value={getMasterLabel("teacher-group",teacherDetails.teacherGroup)} />

            <LabelValue label="Date Of Joining" value={teacherDetails.doj} />
            <LabelValue label="Date Of Birth" value={teacherDetails.dob} />


            <div className="row">
                <div className="col-md-5">
                    <strong>Subjects</strong>
                </div>
                <div className="col-md-7">
                    {getMasterLabel("subject",teacherDetails.primarySubject1)},
                    {getMasterLabel("subject",teacherDetails.primarySubject3)},
                    {getMasterLabel("subject",teacherDetails.primarySubject2)},
                </div>
            </div>
            <hr />
            <TeacherAssignedClassList teacherId={teacherDetails.id} AppAlert={AppAlert} />
            <hr/>
                <div className="row">
                    <div className="col-md-5">
                        <strong>Timetable</strong>
                    </div>
                    <div className="col-md-7">
                        <AwsImageInline
                            label="Teacher Time Table"
                            name="timetableUrl"
                            value={teacherDetails.timetableUrl}
                            editId={teacherDetails.id}
                            updateInline={teacherServices.updateInline}
                            AppAlert={AppAlert}
                            module="teacher-timetable"
                            fileId={teacherDetails.id}
                            onSuccess={refreshData}
                        />
                    </div>
                </div>
                                                                    
            <hr/>
            <LabelValue label="B. Group" value={getMasterLabel("blood-group",teacherDetails.bloodGroup)} />
            <LabelValue label="Religion" value={getMasterLabel("religion",teacherDetails.religion)} />
            <LabelValue label="Cast" value={getMasterLabel("caste",teacherDetails.caste)} />
            <LabelValue label="Category" value={getMasterLabel("category",teacherDetails.category)} />
            <LabelValue label="Nationality" value={getMasterLabel("nationality",teacherDetails.nationality)} />
            <LabelValue label="Marital Status" value={getMasterLabel("marital-status",teacherDetails.	maritalStatus)} />

            <hr/>
            <h5>Bank Details</h5>
            <LabelValue label="PAN" value={teacherDetails.pan} />
            <LabelValue label="Bank Account No" value={teacherDetails.bankAccountNo} />
            <LabelValue label="Bank Name" value={teacherDetails.bankName} />
            <LabelValue label="Bank IFCS" value={teacherDetails.bankIFSC} />
            <LabelValue label="Bank Branch" value={teacherDetails.bankBranch} />

            <div className="row">
                <div className="col-md-12">
                    <strong>Address</strong>
                </div>
                <div className="col-md-12">
                    {teacherDetails.addressLine1}, {teacherDetails.addressLine2}
                    <br/>
                    {teacherDetails.city},{teacherDetails.pincode} 
                </div>
            </div>
            <hr/>
            <h5>Secondary Contact information</h5>
            <h5><PhoneAndroid/> {teacherDetails.secondaryPhone} <Mail/> {teacherDetails.secondaryEmail}</h5>

            <h5>Father / Spouse</h5>
            <h5> {teacherDetails.contactPerson} ({getMasterLabel("relation-type",teacherDetails.contactRelation)})  <PhoneAndroid/> {teacherDetails.contactPhone}</h5>

        </>
    )
}

export default TeacherInformation