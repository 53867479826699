import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout';
import attendanceServices from '../attendanceServices';
import UpdateAttendanceComponent from '../components/UpdateAttendanceComponent';

function EditStudentAttendancePage({AppAlert}) {

    let { batchId } = useParams();

    const [studentList,setStudentList] = useState(null);
    const [existingAttendanceList,setExistingAttendanceList] = useState(null)


    const getStudentList = () =>{

        attendanceServices.studentBatchAttendanceList(batchId).then(res=>{
            setStudentList(res.data);
            setExistingAttendanceList(res.existingAttendanceList);
        }).catch(error=>{
            //AppAlert(error.msg,"error")
        }).finally(()=>{
            
        })
    }


    useEffect(()=>{
        getStudentList();
    },[batchId])

    return (
        <AppLayout>
            <div className="container-fluid mt-3">
                {studentList &&
                    <UpdateAttendanceComponent studentList={studentList} setStudentList={setStudentList} AppAlert={AppAlert} existingAttendanceList={existingAttendanceList} batchId={batchId} />
                }
            </div>
        </AppLayout>
    )
}

export default  withAlert(EditStudentAttendancePage)