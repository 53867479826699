import React, { useEffect, useState } from 'react'
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout'
import systemMasterServices from '../../../master/systemMasterServices';


import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { CircularProgress } from '@mui/material';
import newsServices from '../newsServices';
import moment from 'moment';
import NewsFormComponent from '../components/NewsFormComponent';


function NewsListPage({AppAlert}) {
    
    /* Start Masters Setting  */
    const [masters,setMasters] = useState(null)
    const [searchingInProgress, setSearchingStatus] = useState(false);

    const getMasterList = () => {
        systemMasterServices.getWithAllOptions([
            "branch"
        ]).then(res=>{
            setMasters(res);
        })
    }
    const getMasterLabel = (type, id) => {
        let label = masters[type].find(m => m.value === id)
        return label.label;
    }
    useEffect(()=>{
        getMasterList();
    },[])
    /* End Masters Setting  */


    /* Search Form Work  */
    const [newsList,setNewsList]    = useState(null);
    const initialValues = {
        branch:"",
        //updated_at:moment().format("YYYY-MM-DD"),
    }

    const onSubmit = (values, onSubmitProps) => {
        setSearchingStatus(true)
        newsServices.filter(values).then(res =>{
            setNewsList(res.data);
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSearchingStatus(false);
        })
    }

    const validationRule = Yup.object({
        //branch: Yup.string().required("Required"),
        //class: Yup.string().required("Required"),
        //section: Yup.string().required("Required"),
        //subject: Yup.string().required("Required"),
        //updated_at: Yup.string().required("Required")
    });

    /* Search Form Work  */



    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row pt-4">
                    <div className="col-md-6">
                        <h3>News List </h3>
                    </div>
                    <div className="col-md-6">
                    <span className="float-end">{masters &&  <NewsFormComponent masters={masters} AppAlert={AppAlert} refreshList={onSubmit} /> }</span>
                    </div>
                    <div className="col-md-12">
                    <Formik
                            initialValues={initialValues}
                            validationSchema={validationRule}
                            onSubmit={onSubmit}
                            enableReinitialize validateOnMount
                        >
                            {
                                (formik) => {
                                    //console.log(formik.values);
                                    return (
                                        <Form>
                                            <div className="row p-2">
                                                {masters && 
                                                    <>
                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Branch" name="branch"  
                                                                options={masters['branch']} multiple={false} 
                                                            />
                                                        </div>
                                                        {/*
                                                        <div className="col-md-12 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="date" 
                                                                label="News Date" name="updated_at" 
                                                            />
                                                        </div>
                                                        */}
                                                    </>
                                                }
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-xs-12 p-2" >
                                                    <span className="float-end">
                                                        {searchingInProgress ? 
                                                            <CircularProgress/> 
                                                            :
                                                            <button className="btn btn-success" type="submit">Search</button>
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </Form>
                                    )
                                }
                            }
                        </Formik>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {newsList && newsList.length > 0 ? 
                            newsList.map(newsDetails=>
                            <div className="row mt-2" key={newsDetails.id}>
                                <div className="co-md-12">
                                    <div className="card">
                                        <div className="card-header bg-primary ">
                                            <strong className="text-white">{newsDetails.title}</strong>
                                            <span className="float-end">
                                                {masters &&  <NewsFormComponent masters={masters} AppAlert={AppAlert} refreshList={onSubmit} data={newsDetails} /> }
                                            </span>
                                            <span className="float-end">
                                                {newsDetails.publish === 0 ? 
                                                    <span className="badge bg-warning text-dark">Drafted</span>
                                                    :
                                                    <span className="badge bg-success text-white">Published</span>
                                                    }
                                            </span>
                                        </div>
                                        <div className="card-body mb-0 pb-0">
                                            <p>{newsDetails.details}</p>
                                        </div>
                                        <div className="card-footer">
                                            Branch : <strong>{ getMasterLabel("branch",newsDetails.branch)}</strong>|
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                            :
                            <div className="row">
                                <div className="co-md-12 ">
                                    {Array.isArray(newsList) && <h3>No Record Found</h3> }
                                </div>
                            </div>
                            
                        }
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(NewsListPage)