import React, { useEffect, useState,useRef } from 'react'

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import moment from 'moment';

import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout'
import receiptService from '../receiptServices'
import CollectionFilter from '../components/CollectionFilter';
import userServices from '../../../users/userServices';
import useMasterList from '../../../hooks/useMasterList';

function FeesCollectionListPage({AppAlert}) {

    const [collectioList, setCollectionList] = useState([]);
    const [filters, setFilters] = useState(null);
    

    const [masters] = useMasterList(["class","section","payment-type"],"all");
    const [userDD,setUserDD] = useState();
    const getUserDD = () => {
        userServices.getUserDD().then(res=>{
            setUserDD(state=>[{value:0,label:"For All"},...res.data]);
        })
    }
    useEffect(()=>{
        getUserDD();
    },[]);
    
    
    
    const gridRef = useRef();

    


    const [defaultColDef] = useState({
        floatingFilter: true,
        filterParams: {
          buttons: ['apply'],
        },
        resizable: true,
        width:120,
        filter:true,
        sortable: true,
        cellStyle: { 'textTransform': 'uppercase' },
    })


    const columnDefs = [
            {field:"id",headerName:"Receipt #"},

            
            {field:"Student", valueGetter: params => params.data.studentFirstName+" "+params.data.studentLastName},

            {field:"Receiver", valueGetter: params => params.data.receiverFirstName+" "+params.data.receiverLastName},
            

            {field:"classTitle", headerName:"Class",width:100},
            {field:"sectionTitle", headerName:"Section",width:100},


            
            {field:"transectionNo", headerName:"Transection #", width:150},

            {field:"paymentDate", headerName:"Payment Date", width:170, valueGetter: params => moment(params.data.paymentDate).format("ll")},

            {field:"lateFees"},
            {field:"previousDues"},
            {field:"concession"},
            {field:"payableAmount", headerName:"Total Amount"},
            {field:"paidAmount", headerName:"Received Amount"},


            {field:"dues"},
            {field:"paymentTypeTitle",headerName:"Payment Method"},
            {field:"remark"},
            {field:"studentId"},


        ]


    

    


    const onBtnExport = () => {
        gridRef.current.api.exportDataAsCsv();
    }

    
    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-2">
                    <div className="col-md-12">
                        <CollectionFilter userDD={userDD} masters={masters} setFilters={setFilters} setCollectionReport={setCollectionList} />
                    </div>
                    <div className="col-md-6">
                    </div>
                    <div className="col-md-6">
                        <button type="button" className="btn btn-primary float-end" onClick={onBtnExport} >Export</button>
                    </div>
                </div>

                <div className="row pt-2">
                    <div className="col-md-12">
                        <div className="ag-theme-alpine" style={{height: 550, width:"auto"}}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={collectioList}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                animateRows={true}
                                >
                            </AgGridReact>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(FeesCollectionListPage)
/* field:"paymentDate" */