import React, { useEffect, useState } from 'react'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { CircularProgress } from '@mui/material';
import studentFilterServices from '../studentFilterServices';


const StudentBatchClassSectionFilter = ({setStudentList, masters}) =>{


    const [searchingInProgress, setSearchingStatus] = useState(false);

    const initialValues = {
        branch:"",
        class:"",
        section:"",
    }

    const onSubmit = (values, onSubmitProps) => {
        setSearchingStatus(true);
        studentFilterServices.get(values).then(res =>{
            setStudentList(res.data);
        }).catch(error=>{
            //AppAlert(error.msg,"error")
        }).finally(()=>{
            setSearchingStatus(false);
        })
    }

    const validationRule = Yup.object({
        branch: Yup.string().required("Required"),
        class: Yup.string().required("Required"),
        section: Yup.string().required("Required"),
    });


  return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationRule}
            onSubmit={onSubmit}
            enableReinitialize validateOnMount
        >
            {
                (formik) => {
                    //console.log(formik.values);
                    return (
                        <Form>
                            <div className="row p-2">
                                {masters && 
                                        <>
                                        <div className="col-md-3 col-xs-12 p-2" >
                                            <FormikControl control="autoselect" formik={formik} 
                                                label="Branch" name="branch"  
                                                options={masters['branch']} multiple={false} 
                                            />
                                        </div>
                                        <div className="col-md-3 col-xs-12 p-2" >
                                            <FormikControl control="autoselect" formik={formik} 
                                                label="Class" name="class"  
                                                options={masters['class']} multiple={false} 
                                            />
                                        </div>
                                        <div className="col-md-3 col-xs-12 p-2" >
                                            <FormikControl control="autoselect" formik={formik} 
                                                label="Section" name="section"  
                                                options={masters['section']} multiple={false} 
                                            />
                                        </div>
                                        </>
                                }
                                <div className="col-md-3 col-xs-12 p-2" >
                                {searchingInProgress ? 
                                    <CircularProgress/> 
                                    :
                                    <button className="btn btn-success" type="submit">Search</button>
                                }
                                </div>
                            </div>
                        </Form>
                    )
                }
            }
        </Formik>
    )
}

export default StudentBatchClassSectionFilter