import React, { useEffect, useState } from 'react'
import CommonLoadingSkeleton from '../../../components/CommonLoadingSkeleton';
import ImageInline from '../../../components/InlineEdit/ImageInline';
import ImageInlineAws from '../../../components/InlineEdit/ImageInlineAws';
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout';
import systemMasterServices from '../../../master/systemMasterServices';
import SchoolForm from '../components/SchoolForm';
import schoolServices from '../schoolServices';


const ValueLabel = ({value, label}) => {
    return (
        <div className="row m-1">
            <div className="col-6"><strong>{label}</strong> </div>
            <div className="col-6 border p-1">{value}</div>
        </div>
    )
}

const SchoolSettingsPage = ({AppAlert}) => {

    const [schoolDetails,setSchoolDetails]  =   useState(null);
    const [loading,setLoadingStatus]        =   useState(false);

    const [masters,setMasters] = useState(null);

    const getMasterList = () => {
        systemMasterServices.get(["affiliation"]).then(res=>{
            setMasters(res);
            getSchoolDetails();
        })
    }
    const getMasterLabel = (type, id) => {
        let label = masters[type].find(m => m.value === id)
        return label.label;
    }


    const getSchoolDetails = () => {
        setLoadingStatus(true);
        schoolServices.get().then(res=>{
            setSchoolDetails(res.data);
        }).catch(error=>{

        }).finally(()=>{
            setLoadingStatus(false);
        })
    }

    useEffect(()=>{
        getMasterList();
    },[])

    return (
        <AppLayout>
            <div className="mt-4">
                <div className="container-fluid">
                    {loading && <CommonLoadingSkeleton /> }
                    {!loading && schoolDetails &&
                        <div className="card">
                            <div className="card-header">School Settings 
                                <span className="float-end">
                                    {masters && 
                                        <SchoolForm data={schoolDetails} masters={masters} AppAlert={AppAlert} refreshList={getSchoolDetails} />
                                    }
                                </span>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3>{schoolDetails.title}</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        {/*<ImageInline label={"Logo"} editId={schoolDetails.id} name="logo" value={schoolDetails.logo} updateInline={schoolServices.updateInline}  />*/}

                                        <ImageInlineAws editId={schoolDetails.id} label={"Logo"}  name="logo" 
                                            value={schoolDetails.logo} 
                                            updateInline={schoolServices.updateInline}  
                                            module="school"
                                        />
                                    </div>
                                    <div className="col-md-8">
                                        <ValueLabel label="Session" value={schoolDetails.session} />
                                        <ValueLabel label="Phone" value={schoolDetails.phone} />
                                        <ValueLabel label="Mobile" value={schoolDetails.mobile} />
                                        <ValueLabel label="Email" value={schoolDetails.email} />
                                        <ValueLabel label="School Owner" value={schoolDetails.owner} />
                                        <ValueLabel label="GST" value={schoolDetails.gst} />
                                        <ValueLabel label="PAN" value={schoolDetails.pan} />
                                        <ValueLabel label="Affiliation" value={getMasterLabel("affiliation",schoolDetails.affiliation)} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(SchoolSettingsPage);