import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import ImageInlineAws from '../../../components/InlineEdit/ImageInlineAws';
import withAlert from '../../../hoc/withAlert';
import useMasterList from '../../../hooks/useMasterList';
import AppLayout from '../../../layout/components/AppLayout'
import systemMasterServices from '../../../master/systemMasterServices';
import TeacherInformation from '../components/TeacherInformation';
import teacherServices from '../teacherServices';

function TeacherDetailsPage({AppAlert}) {
    let { id } = useParams();

    {/*  Master Details Start */}

    const [masters,getMasterLabel] = useMasterList(["branch", "city","category","job-contract-type", "area", "gender","department","designation","salutation","marital-status","teacher-group","subject", "state","blood-group","religion","caste","nationality","qualification","relation-type"])
    
    useEffect(()=>{
        getTeacherDetails();
    },[])
    {/*  Master Details Start */}



    const [teacherDetails,setTeacherDetails]    = useState(null);
    const [loading, setLoadingStatus]           = useState(false);

    const getTeacherDetails = () =>{
        setLoadingStatus(true);
        teacherServices.getById(id).then(res=>{
            setTeacherDetails(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setLoadingStatus(false);
        })
    }

    
    

    return (
        <AppLayout>
            <div className="container-fluid mt-3">
                {teacherDetails && 
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card">
                                <ImageInlineAws editId={teacherDetails.id} label={"Photo"} name="photo" 
                                    value={teacherDetails.photo} 
                                    updateInline={teacherServices.updateInline}  
                                    module="teacher"
                                />
                            </div>
                        </div>
                        <div className="col-md-8">
                            
                            <TeacherInformation teacherDetails={teacherDetails} masters={masters} getMasterLabel={getMasterLabel} AppAlert={AppAlert} refreshData={getTeacherDetails} />
                        </div>
                    </div>
                }
                
            </div>
        </AppLayout>
    )
}

export default withAlert(TeacherDetailsPage)