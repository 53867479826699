import React,{useState} from 'react'
import { Dialog, DialogActions, DialogContent,  IconButton} from "@mui/material";
import { Delete, Warning } from '@mui/icons-material';
import studentServices from '../studentServices';
import { useNavigate } from 'react-router';

function StudentRemove({studentDetails,AppAlert}) {

    const [dialogStatus, setDialogStatus] = useState(false);

    const [admissionNo,setAdmissionNo] = useState("");

    const navigate = useNavigate();

    const handleClose = () =>{
        setDialogStatus(false)
    }

    const confirm = (id) =>{
        studentServices.remove(id).then(res=>{
            AppAlert("Removed")
            setTimeout(()=>{
                navigate("/student/list");
            },1000)
        }).catch(error=>{
            AppAlert("Something Went Wrong",error)
        })
    }

    return (
        <>
            <IconButton>
                <Delete onClick={()=>setDialogStatus(true)} />
            </IconButton>

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
                aria-describedby="alert-dialog-slide-description"
            >

                <DialogContent> 
                    <div className="row">
                        <div className="col-md-12">
                            <h4><Warning/> Are you Sure you want to remove this Student</h4>
                            <hr/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <h6> Student Name : <strong> {studentDetails.firstName} {studentDetails.lastName} </strong> </h6>
                            <h6> Admission # : <strong> {studentDetails.admissionNo}/{studentDetails.admissionYear} </strong> </h6>
                            <h6> Enter Admission No in box below to remove student :  </h6>
                            <input type="text" onChange={(e)=>setAdmissionNo(e.target.value)} value={admissionNo} />
                        </div>
                    </div>

                </DialogContent>
                <DialogActions>
                    <button type="button" disabled={admissionNo !== studentDetails.admissionNo} onClick={()=>confirm(studentDetails.id) } className="btn btn-danger">Remove</button>
                    <button type="button" onClick={handleClose} className="btn btn-success">No</button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default StudentRemove